// React imports
import React from 'react'

// Remix imports

// Third party imports

// Generated imports

// App imports
import Button from '~/components/buttons/Button'
import InputText from '~/components/inputs/InputText'
import P3 from '~/components/typography/P3'
import useFormErrorsAndData from '~/hooks/useFormErrorsAndData'
import useFormsDisabled from '~/hooks/useFormsDisabled'
import useGlobal from '~/hooks/useGlobal'
import useLang from '~/hooks/useLang'
import urlReverse from '~/utils/urlReverse'

interface Props extends FormComponent {}

const NewsletterSignupForm: React.FC<Props> = ({ FormElement, actionData }) => {
  const { formErrors, formData } = useFormErrorsAndData(actionData)
  const lang = useLang()
  const global = useGlobal()
  const disabled = useFormsDisabled()

  if (!global) {
    return null
  }

  const trans = global.trans

  return (
    <div className="">
      <P3 className="mx-auto max-w-[250px] text-center">
        {trans.SubscribeForTheLatestUpdates}
      </P3>
      <FormElement
        method="post"
        action={urlReverse('newsletterSignup', { lang })}
        className="mt-3"
      >
        <fieldset
          disabled={disabled}
          className="flex items-start justify-center"
        >
          <InputText
            inputType="normal"
            inputClassName="bg-white"
            defaultValue={formData?.email}
            error={formErrors?.email}
            name="email"
            placeholder={trans.Email}
            required={true}
            disabled={disabled}
          />
          <div>
            <Button
              size="inputNormal"
              disabled={disabled}
            >
              {trans.Subscribe}
            </Button>
          </div>
        </fieldset>
      </FormElement>
    </div>
  )
}

export default NewsletterSignupForm
